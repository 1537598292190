$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * swiper
    // *
    // *
    const zeroPad = (num, places) => String(num).padStart(places, '0')


    let activeIndex = 0;
    let totalSlides = $(".swiper-slide").length;

    let swiper;
    const enableSwiper = function () {
        swiper = new Swiper('.swiper-hero', {
            // Optional parameters
            loop: true,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            on: {
                init: function () {
                    totalSlides = this.slides.length;
                    $caption = $(".swiper-hero").parent(".js-slider").find(".js-slider-caption");
                    $caption.eq(activeIndex).addClass("active");
                    $caption.eq(activeIndex).find(".js-slider-count").text(`${activeIndex + 1}/000${totalSlides}`);

                },
                slideChange: function () {
                    activeIndex = this.realIndex;

                    $caption = $(".swiper-hero").parent(".js-slider").find(".js-slider-caption");
                    $caption.removeClass("active");
                    $caption.eq(activeIndex).addClass("active");
                    $caption.eq(activeIndex).find(".js-slider-count").text(`${activeIndex + 1}/${zeroPad(totalSlides, 4)}`);

                },
                transitionStart: function () {

                    var videos = document.querySelectorAll('video');

                    Array.prototype.forEach.call(videos, function (video) {
                        video.pause();
                    });
                },

                transitionEnd: function () {

                    var activeIndex = this.activeIndex;
                    var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                    var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                    if (activeSlideVideo) {
                        activeSlideVideo.play();
                        // activeSlideVideo.muted = false;
                    }

                },
            },
        });
    }

    $captions = $(".swiper-hero").parent(".js-slider").find(".js-slider-caption-mobile");
    $captions.each(function (index) {
        $(this).find(".js-slider-count").text(`${index + 1}/000${totalSlides}`)
    })
    //  $caption.eq(activeIndex).find(".js-slider-count").text(`${activeIndex + 1}/000${totalSlides}`);

    const breakpoint = window.matchMedia('(max-width:1100px)');
    const breakpointChecker = function () {
        if (breakpoint.matches === true) {
            if (swiper !== undefined) {
                swiper.destroy(true, true);
            }
            return;
        } else if (breakpoint.matches === false) {
            enableSwiper();
            return;
        }
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();


    const swiperSlider = new Swiper('.swiper-slider', {
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
            type: "custom",
            renderCustom: function (swiper, current, total) {
                let result = "";
                if (total > 1) {
                    result = `${current}/${zeroPad(total, 4)}`;
                }

                return result;
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    $(".swiper-slider").each(function () {
        if ($(this).find(".swiper-slide").length == 1) {
            $(this).find(".gallerySingle__pagination").addClass("no-margin")
        }
    })


    let swiperMobileSlider;
    const enableMobileSwiper = function () {
        swiperMobileSlider = new Swiper('.swiper-mobile-slider', {
            loop: true,
            slidesPerView: 1,
            pagination: {
                el: ".swiper-pagination",
                type: "custom",
                renderCustom: function (swiper, current, total) {
                    let result = "";
                    if (total > 1) {
                        result = `${current}/${zeroPad(total, 4)}`;
                    }

                    return result;
                }
            },
            //TODO autoplay funktioniert, verhalten evtl ändern
            on: {
                init: function (swiper) {
                    const currentSlide = swiper.slides[0];
                    const video = currentSlide.querySelector("video");
                    if (video) {
                        // video.muted = true;
                        // video.play();
                    }

                },
                activeIndexChange: function (swiper) {
                    swiper.slides.forEach((slide) => {
                        const video = slide.querySelector("video");
                        if (video) {
                            video.pause();
                        }
                    })

                    const currentSlide = swiper.slides[swiper.activeIndex];
                    const video = currentSlide.querySelector("video");
                    if (video) {
                        // video.muted = true;
                        // video.play();

                    }
                },
            }
        });
    }


    const breakpointMobile = window.matchMedia('(max-width:768px)');
    const breakpointMobileChecker = function () {
        if (breakpointMobile.matches === true) {
            enableMobileSwiper();
            return;
        } else if (breakpointMobile.matches === false && swiperMobileSlider !== undefined) {
            swiperMobileSlider.forEach((element) => {
                if (element !== undefined) {
                    element.destroy(true, true);
                    return;
                }

            })
        }
    };


    breakpointMobile.addListener(breakpointMobileChecker);
    breakpointMobileChecker();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video hover/double tab
    // *
    // *
    $('.js-project-item').on('click', function (e) {
        $(this).toggleClass('active');
        if ($(this).find(".js-project-video").length > 0) {
            $(this).find(".js-project-video").get(0).play();
        }
        $(this).siblings().removeClass('active');
        if ($(this).siblings().find(".js-project-video").length > 0) {
            $(this).siblings().find(".js-project-video").get(0).pause();
            $(this).siblings().find(".js-project-video").get(0).currentTime = 0;
        }
    })

    // double tab mobile
    if (matchMedia('(pointer:coarse)').matches) {
        var touchtime = 0;
        $("video").on("click", function () {
            if (touchtime == 0) {
                // set first click
                touchtime = new Date().getTime();
            } else {
                // compare first click to this click and see if they occurred within double click threshold
                if (((new Date().getTime()) - touchtime) < 800) {
                    // double click occurred
                    this.requestFullscreen();
                    $(this).get(0).play();
                    touchtime = 0;
                } else {
                    // not a double click so set as a new first click
                    touchtime = new Date().getTime();
                }
            }
        });
    }
  



    $('.js-play-video').on("click", function () {
        var parent = $(this).parents(".js-play-video-wrapper");
        parent.toggleClass("active");
        if ($(this).siblings(".js-video-poster").length > 0) {
            $(this).siblings(".js-video-poster").hide();
        }

        $(this).get(0).paused ? $(this).get(0).play() : $(this).get(0).pause();
    });




    $('.js-hover-controls').on("mouseenter", function () {
        $(this).parents(".js-play-video-wrapper").addClass("active");
        $(this).attr('controls', '');
    });
    $('.js-hover-controls').on("mouseleave", function () {
        $(this).parents(".js-play-video-wrapper").removeClass("active");
        $(this).removeAttr('controls');
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * replace text
    // *
    // *
    $(".js-project-replace-text").find("#tvprojectDetails3").text("(...)");

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * preventParenthesesBreak
    // *
    // *
    function preventParenthesesBreak(text) {
        // Use regular expressions to find and replace spaces within parentheses
        return text.replace(/\((.*?)\)/g, function (match, content) {
            // Replace spaces with non-breaking spaces
            const replacedContent = content.replace(/\s/g, '\u00A0');
            return `(${replacedContent})`;
        });
    }

    $(".js-prevent-parenthese-break").each(function () {
        $(this).text(preventParenthesesBreak($(this).text()));
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * preventCommaBreak
    // *
    // *
    var words = $('.js-no-break').text().split(',').map(function (word, index, array) {
        return '<span class="textgrid__nobreak">' + word.trim() + '</span>' + (index < array.length - 1 ? ', ' : '');
    });

    $('.js-no-break').html(words.join(''));

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hero-height
    // *
    // *
    var heightText = 0;
    var windowHeight = $(window).height();
    const heightHeader = 80;
    const heightGaps = 30;
    var variableHeight = 0;
    if ($(window).width() < 900) {
        variableHeight = $(window).width() > 600 ? 15 : -18;
    }
    $(".js-hero-text").each(function () {
        heightText += $(this).height();
    });
    const height = windowHeight - heightText - heightHeader - heightGaps - variableHeight;
    $(".js-hero-image").first().height(height);

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * get image count
    // *
    // *

    $(".js-get-image-count").each(function () {

        var count = $(this).children().length;
        if (count === 1) {
            $(this).addClass("singleImage");
        }
        if (count === 0) {
            $(this).parents(".js-count-remove").remove();
        }
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * count remove
    // *
    // *

    $(".js-count-remove").each(function () {

        var count = $(this).children().length;
        if (count === 0) {
            $(this).parents(".js-count-remove-parent").remove();
        }
    })



});
// * * * * * * * * * * * * * * * * * * * * * * * * *
// * column-widht
// *
// *
$(window).on("load resize", function () {
    var width = $(".js-get-width").width();
    $(".js-set-padding-left").css("padding-left", width + 15);
    $(".js-set-padding-left-right").css({ "padding-left": width + 15, "padding-right": width + 15 });
});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});
